import { Layout, Button, Flex, Spin, Alert } from "antd";
import { useState } from "react";
import ScrapingInstance from "../scrapings/ScrapingInstance";
import { PlusOutlined } from "@ant-design/icons";
import Add from "../scrapings/Add";
import { useGetScrapings } from "../../hooks/user.hook";
import InstallPWAButton from "../shared/InstallPWA";

const { Content } = Layout;

export default function ContentComponent({ onLogout }) {
  const [openAdd, setOpenAdd] = useState(false);
  const { data: scrapings, isLoading, isError, error } = useGetScrapings(); // Użycie hooka

  const closeAddModal = () => {
    setOpenAdd(false);
  };
  window.addEventListener('beforeinstallprompt', (e) => {
    console.log('beforeinstallprompt event fired', e);
  });
  return (
    <Content
      className="site-layout"
      style={{ padding: "0 50px", marginTop: 64 }}
    >
      <Button
        size="large"
        icon={<PlusOutlined />}
        type="primary"
        className="mb-4"
        onClick={() => setOpenAdd(true)}
      >
        Dodaj
      </Button>
      <Add isOpen={openAdd} close={closeAddModal} />
      <InstallPWAButton />
      {isLoading && <div style={{ textAlign: "center", marginTop: "50px" }}>
        <Spin size="large" />
      </div>}
      {isError && <Alert message="Błąd" description={error.message} type="error" showIcon />}
      {!isLoading && !isError && (
        <Flex wrap gap={20}>
          {scrapings.map((scraping) => (
            <ScrapingInstance key={scraping._id} scraping={scraping} />
          ))}
        </Flex>
      )}
    </Content>
  );
}
