import { Layout, Spin } from "antd";
import HeaderComponent from "./components/layout/Header.jsx";
import ContentComponent from "./components/layout/Content.jsx";
import FooterComponent from "./components/layout/Footer.jsx";
import LoginComponent from "./components/layout/Login.jsx";
import { useEffect, useState } from "react";
import { useCheckLogin } from "./hooks/auth.hook.js";

function App() {
  const [loggedIn, setLoggedIn] = useState(false);

  const { data, isLoading, error } = useCheckLogin(); // Используем хук для проверки логина.

  useEffect(() => {
    if (data) {
      setLoggedIn(data); 
    }
  }, [data]);

  const handleLogin = (isLoggedIn) => {
    setLoggedIn(isLoggedIn);
  };

  const handleLogout = () => {
    setLoggedIn(false);
  };

  if (isLoading) {
    return (
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <Layout style={{background: '#F4F5F6'}}>
      {loggedIn ? (
        <>
          <HeaderComponent onLogout={handleLogout} />
          <ContentComponent />
          <FooterComponent />
        </>
      ) : (
        <LoginComponent onLogin={handleLogin} />
      )}
    </Layout>
  );
}

export default App;