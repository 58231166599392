function formatDate(isoDateString) {
    const date = new Date(isoDateString);

    // Użycie wbudowanej funkcji do formatowania daty
    const formattedDate = date.toLocaleDateString("pl-PL", {
        day: "numeric",
        month: "long", // pełna nazwa miesiąca
        year: "numeric",
    });

    // Użycie wbudowanej funkcji do formatowania czasu
    const formattedTime = date.toLocaleTimeString("pl-PL", {
        hour: "2-digit",
        minute: "2-digit",
    });

    return `${formattedDate}, ${formattedTime}`;
}

export default formatDate;