import { Modal, Form, Input, Button, message } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useLogin } from "../../hooks/auth.hook";

export default function LoginComponent({ onLogin }) {
    const [loading, setLoading] = useState(false);
    const { mutate: login } = useLogin();

    const handleAuthorization = (values) => {
        setLoading(true);
        login(values, {
            onSuccess: (data) => {
                onLogin(true); // Przekazanie informacji o zalogowaniu
            },
            onError: (error) => {
                if (error.response?.status === 403) {
                    message.error(error.response.data.message);
                } else {
                    message.error("Błąd logowania");
                }
            },
            onSettled: () => {
                setLoading(false); // Reset loading niezależnie od wyniku
            },
        });
    };

    return (
        <div>
            <Modal
                title="Logowanie"
                width={350}
                open={true}
                footer={null}
                closable={false}
            >
                <Form
                    name="normal_login"
                    className="login-form mt-6"
                    initialValues={{ remember: true }}
                    onFinish={handleAuthorization}
                >
                    <Form.Item
                        name="username"
                        rules={[{ required: true, message: "Login jest wymagany" }]}
                    >
                        <Input
                            prefix={<UserOutlined className="site-form-item-icon" />}
                            placeholder="Username"
                            size="large"
                        />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: "Hasło jest wymagane" }]}
                    >
                        <Input
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder="Password"
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            loading={loading}
                            className="login-form-button"
                        >
                            Zaloguj się
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}