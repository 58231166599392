import { useEffect, useState } from "react";
import {
  Drawer,
  Checkbox,
  Button,
  Space,
  Flex,
  Typography,
  Spin,
  Alert,
  Divider,
} from "antd";
import { useGetRecords } from "../../hooks/user.hook";
import Table from "../shared/Table";
import { useDownloadRecords } from "../../hooks/user.hook";

const { Text } = Typography;

export default function ExportComponent({ isOpen, close, scrapingId }) {
  const [hideEmptyPhones, setHideEmptyPhones] = useState(false);
  const [hideDuplicatePhones, setHideDuplicatePhones] = useState(false);

  const {
    data: records,
    isLoading,
    isError,
    error,
    refetch,
  } = useGetRecords(scrapingId);
  const [fields, setFields] = useState([]);

  // Uruchamianie refetch co 4 sekundy, gdy isOpen jest true
  useEffect(() => {
    let interval;
    if (isOpen) {
      interval = setInterval(() => {
        refetch(); // Wywołanie refetch co 4 sekundy
      }, 4000);
    }

    // Czyszczenie interwału po zamknięciu
    return () => {
      clearInterval(interval);
    };
  }, [isOpen, refetch]);

  useEffect(() => {
    refetch();
  }, [isOpen]);

  const [checkedList, setCheckedList] = useState(
    fields.map((item) => item.value)
  );

  useEffect(() => {
    if (isOpen && records) {
      let allKeys = Array.from(new Set(records.flatMap(Object.keys)));
      setFields(
        allKeys.map((key) => ({
          label: key.replace(key[0], key[0].toUpperCase()),
          value: key,
        }))
      );
      setCheckedList(allKeys);
    }
  }, [isOpen]);

  const { mutate: downloadRecords } = useDownloadRecords();

  return (
    <div>
      <Drawer
        title="Eksportuj do pliku"
        open={isOpen}
        width="90%"
        onClose={() => {
          close();
        }}
        extra={
          <>
            <Space.Compact>
              <Button
                disabled={checkedList.length === 0}
                onClick={() => {
                  downloadRecords({
                    scrapingId,
                    fields: checkedList,
                    format: "xlsx",
                    hideEmptyPhones,
                    hideDuplicatePhones,
                  });
                }}
              >
                Pobierz XLSX
              </Button>
              <Button
                disabled={checkedList.length === 0}
                onClick={() => {
                  downloadRecords({
                    scrapingId,
                    fields: checkedList,
                    format: "csv",
                    hideEmptyPhones,
                    hideDuplicatePhones,
                  });
                }}
              >
                Pobierz CSV
              </Button>
              <Button
                disabled={checkedList.length === 0}
                onClick={() => {
                  downloadRecords({
                    scrapingId,
                    fields: checkedList,
                    format: "json",
                    hideEmptyPhones,
                    hideDuplicatePhones,
                  });
                }}
              >
                Pobierz JSON
              </Button>
              {checkedList.length === 1 && (
                <Button
                  disabled={checkedList.length === 0}
                  onClick={() => {
                    downloadRecords({
                      scrapingId,
                      fields: checkedList,
                      format: "txt",
                      hideEmptyPhones,
                      hideDuplicatePhones,
                    });
                  }}
                >
                  Pobierz TXT
                </Button>
              )}
            </Space.Compact>
          </>
        }
      >
        <Flex gap={0} vertical>
          {/* <Flex align="center" gap={20} justify="">
            <Checkbox
              checked={hideEmptyPhones}
              onChange={() => setHideEmptyPhones(!hideEmptyPhones)}
            >
              Ukryj puste wpisy
            </Checkbox>
            <Checkbox
              checked={hideDuplicatePhones}
              onChange={() => setHideDuplicatePhones(!hideDuplicatePhones)}
            >
              Ukryj wpisy z powtarzającymi się numerami telefonu
            </Checkbox>
          </Flex>
          <Divider /> */}
          <Flex align="center" gap={20} justify="" className="mb-4">
            <Text strong>Pola do eksportu</Text>
            <Space>
              <Button type="link" onClick={() => setCheckedList([])}>
                Odznacz wszystkie
              </Button>
              <Button
                type="link"
                onClick={() =>
                  setCheckedList([...fields.map((item) => item.value)])
                }
              >
                Zaznacz wszystkie
              </Button>
            </Space>
          </Flex>
          <Checkbox.Group
            options={fields}
            value={checkedList}
            onChange={(list) => setCheckedList(list)}
            className="mb-8"
          />
        </Flex>
        {isLoading && <Spin size="large" />}
        {isError && (
          <Alert
            message="Błąd podczas pobierania rekordów"
            description={error.message}
            type="error"
            showIcon
          />
        )}
        {!isLoading && !isError && (
          <Table
            records={records}
            loading={isLoading}
            activeFields={checkedList}
            // hideEmpty={hideEmptyPhones}
            // hideDuplicates={hideDuplicatePhones}
          />
        )}
      </Drawer>
    </div>
  );
}
