import axios from '../middlewares/axios.middleware';

class AuthService {
    static login = async (credentials) => {
        try {
            const res = await axios.post("/api/auth/login", credentials);
            if (res.status === 200) {
                return res.data;
            }
            throw new Error(`Unexpected status code: ${res.status}`);
        } catch (error) {
            console.error("Błąd logowania:", error.response?.data || error.message);
            throw error;
        }
    };

    static saveToken = (token) => {
        localStorage.setItem("auth", token);
    };

    static getToken = () => {
        return localStorage.getItem("auth");
    };

    static removeToken = () => {
        localStorage.removeItem("auth");
    };

    static async checkLogin() {
        try {
            // Отправляем запрос проверки логина.
            const response = await axios.post("/api/auth/login");
            return response.status === 200;
        } catch (error) {
            console.error("Ошибка проверки логина:", error);
            // Если запрос завершился ошибкой (например, 401), возвращаем false.
            return false;
        }
    }
}

export default AuthService;