import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Space,
  Progress,
  Typography,
  Spin,
  Flex,
  Tooltip,
} from "antd";
import {
  PlayCircleOutlined,
  DeleteOutlined,
  DownloadOutlined,
  CalendarOutlined,
  LoadingOutlined,
  StopOutlined,
  PauseCircleOutlined,
} from "@ant-design/icons";
import ExportComponent from "./Export";
import {
  useGetRecordsCount,
  useDeleteScraping,
  useStartScraping,
  useStopScraping,
  useGetScraping,
} from "../../hooks/user.hook";
import formatDate from "../../helpers/formatDate";

const { Text, Link } = Typography;

const decomposeUrl = (url) => {
  if (!url) return [];
  try {
    url = new URL(url);
  } catch (error) {
    console.log("Failed to parse URL:", url);
    console.error(error);
  }
  let path = url.pathname;
  path = path.split("/");
  path = path.map((item) => capitalizeFirstLetter(item));
  if (path[path.length - 1] === "") path.pop();
  return path.slice(1);
};

function capitalizeFirstLetter(val) {
  return String(val).charAt(0).toUpperCase() + String(val).slice(1);
}

export default function ScrapingInstance({ scraping }) {
  const [openExport, setOpenExport] = useState(false);
  const { mutate: deleteScraping, isLoading: isDeleting } = useDeleteScraping();
  const {
    mutate: startScraping,
    isPending: isStarting,
    isSuccess: started,
  } = useStartScraping();
  const {
    mutate: stopScraping,
    isPending: isStoping,
    isSuccess: stopped,
  } = useStopScraping();
  const { data: scrapingData, refetch: refetchScraping } = useGetScraping(
    scraping._id
  );
  const {
    data: recordsCount,
    isLoading,
    isError,
    error,
    refetch: refetchRecordsCount,
  } = useGetRecordsCount(scraping._id);

  // Użyj useEffect do automatycznego odświeżania
  useEffect(() => {
    let interval;

    if (scraping.isRunning) {
      interval = setInterval(() => {
        refetchScraping(); // Odśwież dane scrapingu
        refetchRecordsCount();
      }, 4000); // 4 sekundy
    }

    return () => {
      clearInterval(interval); // Wyczyść timer, gdy scraping przestaje działać
    };
  }, [scraping.isRunning, refetchScraping]);

  const closeExportDrawer = () => {
    setOpenExport(false);
  };

  if (isError) {
    console.error("Failed to fetch records count:", error);
  }

  useEffect(() => {
    refetchScraping();
  }, [started, stopped]);

  const calcPercentage = () => {
    if (scraping.isFinished) {
      return 100
    }
    if (scraping.lastPageNumber > 0) {
      return Number(
        (scraping.currentPageNumber / scraping.lastPageNumber) * 100
      ).toFixed(0);
    } else {
      return 0;
    }
  };

  const currentScraping = scrapingData || scraping; // Użyj zaktualizowanych danych

  return (
    <div className="w-full">
      <Card
        title={
          <Space>
            <Progress type="dashboard" percent={calcPercentage()} size={40} />
            <span className="ml-2">
              {decomposeUrl(currentScraping.initialURL).join(" / ")}
            </span>
          </Space>
        }
        extra={
          <Space>
            <Space className="mr-4">
              <Text type="secondary">Strona:</Text>
              <Text>
                {currentScraping.currentPageNumber} /{" "}
                {currentScraping.lastPageNumber}
              </Text>
              <Text type="secondary">Rekordy:</Text>
              {isLoading && (
                <Spin indicator={<LoadingOutlined spin />} size="small" />
              )}
              {isError && <Text type="danger">Błąd</Text>}
              {!isLoading && !isError && <Text>{recordsCount || 0}</Text>}
            </Space>
            <Tooltip title="Eksportuj">
              <Button
                icon={<DownloadOutlined />}
                onClick={() => setOpenExport(true)}
              />
            </Tooltip>
            <Tooltip title={currentScraping.isRunning ? "Stop" : "Start"}>
              <Button
                type="primary"
                loading={isStarting || isStoping}
                icon={
                  currentScraping.isRunning ? (
                    <PauseCircleOutlined />
                  ) : (
                    <PlayCircleOutlined />
                  )
                }
                onClick={async () => {
                  if (currentScraping.isRunning) {
                    stopScraping(scraping._id);
                  } else {
                    startScraping(scraping._id);
                  }
                }}
              />
            </Tooltip>
            <Tooltip title="Usuń">
              <Button
                type="primary"
                icon={<DeleteOutlined />}
                disabled={currentScraping.isRunning || isDeleting}
                danger
                loading={isDeleting}
                onClick={() => deleteScraping(scraping._id)}
              />
            </Tooltip>
          </Space>
        }
        className="w-[100%]"
      >
        <Flex vertical gap={16}>
          <Text copyable ellipsis code>
            {currentScraping.initialURL}
          </Text>
          <Flex justify="space-between">
            <Text type="secondary">
              <CalendarOutlined className="mr-2" />
              {formatDate(currentScraping.createdAt)}
            </Text>
            <Text type="secondary">ID: {currentScraping._id}</Text>
          </Flex>
        </Flex>
      </Card>
      <ExportComponent
        isOpen={openExport}
        close={closeExportDrawer}
        scrapingId={scraping._id}
      />
    </div>
  );
}
