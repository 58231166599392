import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import AuthService from "../services/auth.service.js";
import { message } from "antd";

/**
 * Хук для авторизации пользователя.
 * @returns {Object} Мутация для логина.
 */
export const useLogin = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: AuthService.login, // Функция логина
        onSuccess: (data) => {
            if (!data?.token) {
                console.error("Login error: Missing token in response");
                return;
            }

            // Сохраняем токен и обновляем кэш
            AuthService.saveToken(data.token);
            queryClient.clear()
            queryClient.invalidateQueries({ queryKey: ["user"] });
        },
        onError: (error) => {
            console.error("Login error:", error.response?.data || error.message);
        },
    });
};

/**
 * Хук для проверки статуса авторизации пользователя.
 * @returns {Object} Query для проверки авторизации.
 */
export const useCheckLogin = () => {
    return useQuery({
        queryKey: ["checkLogin"],
        queryFn: AuthService.checkLogin, // Функция проверки логина
        retry: false, // Отключаем автоматические повторные запросы при ошибках
        onError: (error) => {
            console.error("Error checking login:", error.response?.data || error.message);
        },
    });
};

/**
 * Хук для выхода из системы (удаления токена).
 * @returns {Object} Мутация для логаута.
 */
export const useLogout = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: () => {
            AuthService.removeToken(); // Удаляем токен из localStorage
        },
        onSuccess: () => {
            // Сбрасываем кэш авторизации и пользователя
            queryClient.clear();
        },
        onError: (error) => {
            console.error("Logout error:", error.response?.data || error.message);
        },
    });
};