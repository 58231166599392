import axios from 'axios';
import config from '../config';

var instance;

if (config.MODE.includes('dev')) {
  instance = axios.create({
    baseURL: 'http://localhost:' + config.SERVER_PORT,
  });  
} else {
  instance = axios.create({
    baseURL: config.SERVER_HOST + ':' + config.SERVER_PORT,
  });
}

// Request interceptor for API calls
instance.interceptors.request.use(
  async config => {
    const token = localStorage.getItem('auth');
    if (token) {
      config.headers = { 
        'Authorization': token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }
    return config;
  },
  error => {
    Promise.reject(error)
});

export default instance;
